<template>
    <div>
        <div class="terms-condtion" v-if="view === 1">
            <h5>TERMS CONDTION</h5>
            <h6>Lorem Ipsum</h6>
            <p>
                Lorem ipsum dolor sit amet consectetur. Amet at nunc egestas enim proin tincidunt purus nisl suspendisse. Consectetur enim aliquet semper gravida sed vestibulum adipiscing. Sed urna hac lacinia orci vitae magna etiam nulla tempus. Orci tellus dapibus dolor magna fringilla hac. Morbi egestas faucibus dignissim metus sed purus ut. Mattis sem felis velit sed dictum amet nulla.s
            </p>

            <div class="form-group form-check mt-4">
                <input type="checkbox" class="form-check-input checkbox" id="checkme">
                <label class="form-check-label" for="checkme">I have read and agreed to Smart terms and conditions</label>
            </div>

            <div class="continue-btn">
                <button class="btn" @click="view = 2">Continue</button>
            </div>
        </div>

        <div class="listing-holder" v-if="view === 2">
            <div class="listing-analytics d-none">
                <div class="row">
                    <div class="col-md-4">
                        <div class="analytics activeL">
                            <h6>Active Listing</h6>
                            <h3>0</h3>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="analytics newL">
                            <h6>Active Listing</h6>
                            <h3>0</h3>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="analytics appointment">
                            <h6>Active Listing</h6>
                            <h3>0</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="listing-tab-holder">
                <div class="listing-tab">
                    <div class="left-list-tab">
                        <h6 @click="tabList = 1"  :class="[tabList === 1 ? 'activeListTab' : '']">My listing {{ properties.length }}</h6>
                        <!-- <h6 @click="tabList = 2" :class="[tabList === 2 ? 'activeListTab' : '']">Exclusive listing 1</h6> -->
                    </div>
                    <div class="right-list-tab">
                        <div>
                            <h6>Location <i class="fa fa-chevron-down"></i></h6>
                            <h6>Date <i class="fa fa-chevron-down"></i></h6>
                        </div>
                        <div class="add-icon">
                            <router-link to="/user/agent-add-property">
                            +
                           </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="listing-items-holder">
                <div class="no-listing">
                    <div v-if="properties.length == 0 && !loadingProperties">
                        <img class="" src="../assets/icons/safe-home.svg" alt="No listing">
                        <h5>No active listing</h5>
                        <h4>Ready to showcase properties and connect with potential renters?</h4>
                        <h4>Let's get your first property listed!</h4>

                        <button class="btn">
                            <router-link to="/user/agent-add-property">
                                Add property
                            </router-link>
                        </button>
                    </div>
                    
                    <div class="m-auto text-center mt-4" v-if="loadingProperties"><Loader/></div>
                    <div class="row" v-else>
                        <div class="col-md-4 col-sm-12"  v-for="(item, index) in properties" :key="index" style="margin-bottom: 30px;">
                            <div class="property-preview-holder" @click="propertyDetailsView(item.id)">
                                <div class="status-text" v-if="item.status">
                                    <span>{{item.status}}</span>
                                </div>
                                <div class="image-container">
                                    <img v-if="item.images.length == 0" class="preview-img" src="../assets/images/property-img.jpg" alt="image">
                                    <img v-else class="preview-img" :src="item.images[0]" alt="image">
                                </div>
                                <div class="prop-preview-details">
                                    <h5>{{item.title}}</h5>
                                    <h6>{{item.city}}, {{item.state}}</h6>
                                    <h4>₦{{Number(item.price).toLocaleString()}}</h4>
                                    <small>{{ item.bedrooms }} bedroom | {{ item.bathrooms }} bathroom</small>
                                    <p>Listed by {{ user.lastname }} | <span>{{  item.businesstype == 1 ? 'For sale' : 'For rent'  }}</span> </p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-4 col-sm-12" style="margin-bottom: 30px;">
                            <div class="property-preview-holder">
                            <div>
                                <img class="preview-img" src="../assets/images/property-img.jpg" alt="image">
                            </div>
                            <div class="prop-preview-details">
                                <h5>Fully Furnished Smart Studio...</h5>
                                <h6>Garriki, Abuja</h6>
                                <h4>₦1,000,000</h4>
                                <small>1 bedroom | 1 bathroom</small>
                                <p>Listed by Joe Doe | <span>For sale</span> </p>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12" style="margin-bottom: 30px;">
                            <div class="property-preview-holder">
                            <div>
                                <img class="preview-img" src="../assets/images/property-img.jpg" alt="image">
                            </div>
                            <div class="prop-preview-details">
                                <h5>Fully Furnished Smart Studio...</h5>
                                <h6>Garriki, Abuja</h6>
                                <h4>₦1,000,000</h4>
                                <small>1 bedroom | 1 bathroom</small>
                                <p>Listed by Joe Doe | <span>For sale</span> </p>
                            </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import properties from "../services/properties";
// import CF from "../helpers/currencyFormatter";
// import currencyFormatter from "../helpers/currencyFormatter";
import Loader from './Loader';


// import $ from "jquery";
export default {
  name: "SmartAgent",
  components: {
    Loader,
  },
  data() {
    return {
        loadingProperties: false,
        // loading: false,
        // userID: null,
        view: 1,
        tabList: 1,
        user: "",
        properties: [],
    };
  },
  created() {
    this.user = this.$store.getters.getUser;
    // const user = this.$store.getters.getUser;
    // this.userID = user.id;
  },
  mounted() {
    this.getAgentProperties();
  },
  beforeDestroy() {
  },
  methods: {
    propertyDetailsView(id) {
      // this.$router.push({ name: 'home' });
      this.$router.push({path: `/user/agent-property-details/${id}`});
    },

    getAgentProperties() {
      this.loadingProperties = true;
      properties
        .fetchAgentProperties(this.user.id)
        .then((res) => {
          this.loadingProperties = false;
          console.log("Propeties", res);
          if (res.success) {
            this.properties = res.properties;
          }
          else {
            this.$toast.error('error occured');
          }
        })
        .catch((err) => {
          this.oadingProperties = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$sheltaBlack: #2B3352;

// Terms and condition starts
.terms-condtion {
    h5 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $asheGrey;
    }
    h6 {
        font-family: Lato;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: justified;
        color: #000A2F;
    }
    p {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: justified;
        color: $asheGrey;
    }
}

.checkbox {
    width: 18px;
    height: 18px;
}
.form-check-label {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: $asheGrey;
    padding-left: 5px;
    padding-top: 2px;
}
.continue-btn {
    display: flex;
    justify-content: right;
    margin-top: 50px;
    button {
        width: 253px;
        height: 48px;
        background: $primary;
        color: $white;
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        outline: 0;
        box-shadow: none;
        font-weight: 500;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
}

// terms and condition end


// LISTING STARTS
.listing-holder {
    margin-top: 30px;
}
.listing-analytics {
    box-shadow: 0px 1px 2px 0px #0000000F;
    box-shadow: 0px 1px 3px 0px #0000001A;
    background: $white;
    border-radius: 20px;
    padding: 24px;
    // margin-bottom: 20px;
}
.activeL {
    background: #F5F7FE;
}
.newL {
    background: #F1ECFE;
}
.appointment {
    background: #FFF7E6;
}
.analytics {
    border-radius: 10px;
    padding: 16px;
    h6, h3 {
        font-family: Lato;
        font-weight: 600;
        text-align: left;
        color: $asheGrey
    }
    h6 {
        font-family: Lato;
        font-size: 16px;
        line-height: 20px;
        color: $asheGrey
    }
    h3 {
        font-size: 64px;
        line-height: 80px;
    }
}

.listing-tab-holder {
    margin-top: 30px;
}
.listing-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.left-list-tab, .right-list-tab {
    display: flex;
    align-items: center;
    gap: 20px;
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: $asheGrey;
        cursor: pointer;

    }
}
.left-list-tab {
    h6 {
        padding: 8px 16px;
    }
}

.right-list-tab div:first-child {
    display: flex;
    align-items: center;
    gap: 15px;
}

.right-list-tab div:nth-child(2) {
    border: 1px solid $asheGrey;
    border-radius: 50px;
    padding: 5px 12px;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.activeListTab {
    color: $primary !important;
    background: $white;
    border-radius: 50px;
}

.listing-items-holder {
    background: $white;
    border-radius: 10px;
}

.no-listing {
    padding: 20px;
    text-align: center;
    margin-top: 10px;
    // margin-bottom: 30px;
    img {
        text-align: center;
    }
    h5 {
        margin-top: 10px;
        font-family: Lato;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        text-align: center;
        color: #2B3352;
    }
    h4 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: $asheGrey;
        margin-bottom: 0;
    }
    button {
        margin-top: 40px;
        margin-bottom: 20px;
        background: $primary;
        color: $white;
        height: 36px;
        width: 143px;
        font-family: Lato;
        font-size: 14px;
        outline: 0;
        box-shadow: none;
        font-weight: 500;
        // line-height: 17.5px;
        // text-align: left;
        a {
            text-decoration: none;
            color: inherit;
            color: inherit;
            text-decoration: none;
            color: inherit;
            display: contents;
        }

    }
}
.add-icon {
   a {
     color: inherit;
     text-decoration: none;
     color: inherit;
     display: contents;
   }
}
// LISTING ENDS


// PROPERTY CARD PREVIEW STARTS
.property-preview-holder {
  box-shadow: 0px 4px 15px 0px #00000026;
  border-radius: 10px;
  padding: 16px;
  position: relative;
  cursor: pointer;
}

.status-text {
    background: $white;
    border-radius: 50px;
    position: absolute;
    left: 6%;
    top: 6%;
    padding: 4px 12px;
    span {
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        text-align: left;
        color: $asheGrey;
    }
}
.image-container {
    max-width: 330px; /* Set the desired width */
    // height: auto;
    height: 193px;
    overflow: hidden
}
.preview-img {
    border-radius: 10px;
    // max-width: 325.33px;
    min-height: 93px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prop-preview-details {
  h5 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #555C74;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h6 {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #555C74;
  }
  h4 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.5px;
    text-align: left;
    color: #2B3352;
  }
  small {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #555C74;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
  }
  p {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #3E4958;
    span {
      color: #EA0033;
    }
  }
}
// PROPERTY CARD PREVIEW ENDS


@media screen and (max-width: 599px) {
    // terms and condtion starts
    .continue-btn {
        justify-content: center;
    }
    // terms and condition ends

    // Listing starts
    .analytics {
        margin-bottom: 20px;
        h3 {
            font-size: 40px;
        }
    }
    .property-preview-holder {
        margin-bottom: 20px;
    }
    .listing-tab {
        display: block;
    }
    .right-list-tab {
        justify-content: space-between;
        margin-top: 15px;
    }
    // listing ends
}
</style>