import client from "./requestclient";

const properties = {
  fetchForRent: async () => {
    return await client({ URL_PATH: `fetchpropertiesrandomly`, method: "GET" });
  },

  fetchForSale: async () => {
    return await client({ URL_PATH: `fetchpropertiesforsale`, method: "GET" });
  },

  fetchPropertyType: async () => {
    return await client({ URL_PATH: `fetchpropertytypes`, method: "GET" });
  },
  fetchPropertySubType: async id => {
    return await client({
      URL_PATH: `fetchpropertysubtypesbytypeid/${id}`,
      method: "GET"
    });
  },
  fetchBusinessTypes: async () => {
    return await client({
      URL_PATH: "newfetchbusinesstypes",
      method: "GET"
    });
  },
  fetchPropertyStatuses: async () => {
    return await client({
      URL_PATH: `fetchpropertystatuses`,
      method: "GET"
    });
  },
  fetchStates: async () => {
    return await client({
      URL_PATH: `fetchstatesbycountryid/161`,
      method: "GET"
    });
  },
  fetchCities: async (id) => {
    return await client({
      URL_PATH: `fetchcitiesbystateid/${id}`,
      method: "GET"
    });
  },
  fetchPropertyAwarenessTypes: async () => {
    return await client({
      URL_PATH: "fetchpropertyawarenestypes",
      method: "Get"
    });
  },
  verifyEmail: async email => {
    return await client({
      URL_PATH: `checkuserwithemailexist/${email}`,
      method: "GET"
    });
  },
  registerIdentifiedProperty: async body => {
    return await client({
      URL_PATH: "processaddpropertyrepository",
      method: "POST",
      body: body
    });
  },
  agentRegisterProperty: async body => {
    return await client({
      URL_PATH: "smart-agent-upload-property",
      method: "POST",
      body: body
    });
  },
  uploadPropertyEvidenceOfOwener: async (formData) => {
    return await client({
      URL_PATH: "uploadevidenceofownership",
      method: "POST",
      body: formData,
      bodyType: "FormData"
    });
  },
  uploadPropertyimages: async (formData) => {
    return await client({
      URL_PATH: "uploadpropertyimage",
      method: "POST",
      body: formData,
      bodyType: "FormData"
    });
  },
  fetchFacilitiess: async () => {
    return await client({
      URL_PATH: `fetchfacilities`,
      method: "GET"
    });
  },
  fetchAgentProperties: async (id) => {
    return await client({
      URL_PATH: `smart-agent-properties-by-id/${id}`,
      method: "GET"
    });
  },
  fetchAgentPropertiesById: async (id) => {
    return await client({
      URL_PATH: `fetchpropertybyid/${id}`,
      method: "GET"
    });
  },
};

export default properties;
